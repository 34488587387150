import React from 'react';

function GradientButton(props) {
  return (
    <div
      aria-hidden="true"
      id="Register_1"
      onClick={props.clickRegisterHandler}
      className="gradient-button font-loader"
    >
      {props.title}
    </div>
  );
}

export { GradientButton };
