import './App.css';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import BoostrapForm from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import WebFont from 'webfontloader';
import { gsap } from 'gsap';
// eslint-disable-next-line import/extensions
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import header from './assets/header.png';
import header2 from './assets/header2.png';
import Form from './components/Form';
import Footer from './components/Footer';
import { GradientButton } from './components/Buttons';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  const [programs, setPrograms] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [radius, setRadius] = useState('');
  const [isSpeakerModalVisible, setIsSpeakerModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [isRegisterClicked, setIsRegisterClicked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    fetch('/api/programs/')
      .then((response) => response.json())
      .then((data) => setPrograms(data));
    WebFont.load({
      google: {
        families: ['Montserrat']
      }
    });
  }, []);

  useEffect(() => {
    const lines = gsap.utils.toArray('.speaker-content');
    lines.forEach((line) => {
      gsap.from(line, {
        y: 100,
        opacity: 0,
        scrollTrigger: {
          trigger: line,
          start: 'top bottom',
          end: 'top 80%',
          scrub: true
        }
      });
    });
  }, [programs]);

  // add or removes color classes to select text
  const changeSelectColorBlack = () => {
    document.getElementById('radius').classList.remove('grey-select');
    document.getElementById('radius').classList.add('black');
  };

  const changeSelectColorGrey = () => {
    document.getElementById('radius').classList.remove('black');
    document.getElementById('radius').classList.add('grey-select');
  };

  const handleZipcodeChange = (e) => setZipcode(e.target.value);

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);

    // depending on radius select value color is changed
    if (e.target.value === '') {
      changeSelectColorGrey();
    } else {
      changeSelectColorBlack();
    }
  };

  const handleSubmitClick = () => {
    fetch(`/api/programs/?zipcode=${zipcode}&radius=${radius}`)
      .then((response) => response.json())
      .then((data) => setPrograms(data));
  };

  const handleSpeakerClick = (program) => {
    setSelectedProgram(program);
    setIsSpeakerModalVisible(true);
  };

  const handleRegisterClick = (program) => {
    setSelectedProgram(program);
    setIsRegisterModalVisible(true);
  };

  const closeRegisterClick = () => {
    setIsRegisterClicked(false);
  };

  const handleClearClick = () => {
    changeSelectColorGrey();
    setZipcode('');
    setRadius('');
    fetch('/api/programs/')
      .then((response) => response.json())
      .then((data) => setPrograms(data));
  };

  const handleSpeakerModalClose = () => setIsSpeakerModalVisible(false);
  const handleRegisterModalClose = () => {
    setIsRegisterModalVisible(false);
    closeRegisterClick();
  };

  return (
    <div className="App">
      {isError && (
        <Alert
          variant="danger"
          className="sticky-top alert"
          onClose={() => setIsError(false)}
          dismissible
        >
          {errorMessage}
        </Alert>
      )}
      <div className="yoy">
        <img
          className="hide-image"
          style={{ maxWidth: '100%', width: '100%' }}
          src={header}
          alt="Novartis"
        />
        <img
          className="hide-image2"
          style={{ maxWidth: '100%', width: '100%' }}
          src={header2}
          alt=""
        />
      </div>
      <div className="body">
        <p className="body1 font-loader">
          Thank you for your interest in attending an upcoming live roundtable program, sponsored by
          Jazz Pharmaceuticals, Inc. Please click on a program to register.
        </p>
        <p className="body2 font-loader">
          Additional information on our expert moderators can be found by clicking on their name.
        </p>
      </div>
      <div className="body-wrapper">
        <div className="sya font-loader">Search your area:</div>
        <div className="form-flex">
          <BoostrapForm.Control
            className="zip-code"
            type="text"
            value={zipcode}
            placeholder="Zip Code"
            onChange={handleZipcodeChange}
          />
          <BoostrapForm.Select
            id="radius"
            className="radius"
            value={radius}
            onChange={handleRadiusChange}
          >
            <option value="">Radius</option>
            <option value="5">5</option>
            <option value="10">10</option>
          </BoostrapForm.Select>
          <div
            id="Search_Button"
            aria-hidden="true"
            className="search-button font-loader"
            onClick={handleSubmitClick}
          >
            Search
          </div>
          <div
            id="Clear_Results"
            aria-hidden="true"
            className="clear-button font-loader"
            onClick={handleClearClick}
          >
            Clear Results
          </div>
        </div>

        <div className="speaker-section">
          {programs.map((program, idx) => (
            <div className="speaker-content font-loader" key={idx}>
              <div className="program-info">
                <div className="program-heading">
                  {program.city}, {program.state}, {program.zipcode}
                </div>

                <div className="program-info-spacing grey program-dates">
                  {dayjs(program.datetime).format('MMM D, YYYY')}
                </div>
                <div className="grey program-dates">
                  {dayjs(program.datetime).format('h:mm a')} {program.timezone}
                </div>
                <div className="grey program-dates">{program.venue}</div>
                <div className="grey program-dates">{program.venue_address}</div>
                <a
                  id="Speaker_Modal"
                  href=""
                  className="grey program-dates bold"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSpeakerClick(program);
                  }}
                >
                  Moderator: {program.speaker.name}, {program.speaker.credentials}
                </a>
              </div>
              <div>
                {!program.isMax && (
                  <GradientButton
                    title="REGISTER"
                    clickRegisterHandler={(e) => {
                      e.preventDefault();
                      handleRegisterClick(program);
                    }}
                  />
                )}
                {program.isMax && (
                  <>
                    <div className="disable-register max-program-display">REGISTER</div>
                    <div
                      style={{
                        fontSize: '12px',
                        color: 'red',
                        paddingTop: '10px',
                        fontWeight: '700'
                      }}
                      className="max-program-display"
                    >
                      This program is currently at capacity. Please check back soon for future
                      programs in this area.
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="grey contact-text">
          Please contact{' '}
          <a id="Meetings_Contact" href="mailto:meetings@interactiveforums.com">
            meetings@interactiveforums.com
          </a>{' '}
          with any questions about the program.
        </div>
      </div>
      <Footer />
      {/* Speaker Modal */}
      <Modal show={isSpeakerModalVisible} onHide={handleSpeakerModalClose}>
        <Modal.Header>
          <i
            aria-hidden="true"
            className="bi bi-x-lg cursor-pointer"
            style={{ marginLeft: 'auto' }}
            onClick={(e) => setIsSpeakerModalVisible(false)}
          />
          <Modal.Title />
        </Modal.Header>
        <Modal.Body>
          <div className="speaker-modal-container">
            <div className="speaker-image">
              <img
                src={selectedProgram.speaker ? selectedProgram.speaker.photo : ''}
                alt="SpeakerImage"
                style={{ width: '125px' }}
              />
            </div>
            <div className="speaker-modal-bio">
              <div className="speaker-modal-name">
                {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''}
              </div>
              <div className="speaker-modal-bio-text">
                {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''},{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedProgram.speaker ? selectedProgram.speaker.bio : ''
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Register Modal */}
      <Modal show={isRegisterModalVisible} onHide={handleRegisterModalClose}>
        <Modal.Header>
          <i
            aria-hidden="true"
            className="bi bi-x-lg cursor-pointer"
            style={{ marginLeft: 'auto' }}
            onClick={handleRegisterModalClose}
          />

          <Modal.Title />
        </Modal.Header>
        <Modal.Body>
          <div className="register-form-layout font-loader">
            {!isRegisterClicked && (
              <div className="register-program-info">
                <div>
                  {dayjs(selectedProgram.datetime ? selectedProgram.datetime : '').format(
                    'MMM D, YYYY'
                  )}
                </div>
                <div>
                  {dayjs(selectedProgram.datetime ? selectedProgram.datetime : '').format('h:mm a')}{' '}
                  {selectedProgram.timezone ? selectedProgram.timezone : ''}
                </div>
                <div>{selectedProgram.venue ? selectedProgram.venue : ''}</div>
                <div className="bold">
                  {selectedProgram.city ? selectedProgram.city : ''},{' '}
                  {selectedProgram.state ? selectedProgram.state : ''},{' '}
                  {selectedProgram.zipcode ? selectedProgram.zipcode : ''}
                </div>
              </div>
            )}

            {!isRegisterClicked && (
              <div className="register-modal-speaker">
                Moderator: {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''}
              </div>
            )}
            <Form
              program={selectedProgram}
              stateChanger={setIsRegisterClicked}
              errorChanger={setIsError}
              showRegisterModal={setIsRegisterModalVisible}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
