import React from 'react';
import JazzLogo from '../assets/jazz_logo_mobile.svg';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img alt="Jazz" src={JazzLogo} />
      </div>
      <div className="footer-links">
        <div>
          <a
            id="Privacy_Statement"
            href="https://www.jazzpharma.com/privacy-statement/ "
            rel="noreferrer"
            target="_blank"
            className="grey"
          >
            Privacy Statement
          </a>
        </div>
        <div>
          <a
            id="Terms_of_Use"
            rel="noreferrer"
            href="https://www.jazzpharma.com/terms-of-use/"
            target="_blank"
            className="grey"
          >
            Terms Of Use
          </a>
        </div>
      </div>
      <div className="footer-copyright grey">
        <div>&#169;2022 Jazz Pharmaceuticals plc or its subsidiaries</div>
        <div>US-SLE-2100215 REV0122</div>
      </div>
    </div>
  );
}

export default Footer;
