import React, { useState } from 'react';
import BoostrapForm from 'react-bootstrap/Form';
import successCheck from '../assets/success-check.png';
import { GradientButton } from './Buttons';

function Form({ program, stateChanger, errorChanger, showRegisterModal, setErrorMessage }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [npi, setNpi] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [emailValid, setEmailValid] = useState(true);

  console.log(program);

  const handleSuccessClick = () => {
    setIsSuccess(true);
  };

  // add or removes color classes to select text
  const changeFormSelectColorBlack = () => {
    document.getElementById('state').classList.remove('grey-select');
    document.getElementById('state').classList.add('black');
  };

  const changeFormSelectColorGrey = () => {
    document.getElementById('state').classList.remove('black');
    document.getElementById('state').classList.add('grey-select');
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    // depending what select value is color is changed
    if (e.target.value === '') {
      changeFormSelectColorGrey();
    } else {
      changeFormSelectColorBlack();
    }
  };

  const emailIsValid = () => {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailValid(false);
      return false;
    }
    setEmailValid(true);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailIsValid()) {
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      state: state,
      npi: npi,
      phone_number: phoneNumber,
      program: program.id
    };
    fetch('/api/registrant/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      console.log(response);
      if (response.status === 406) {
        setErrorMessage(
          'This program is currently at capacity. Please check back soon for future programs in this area.'
        );
        errorChanger(true);
        showRegisterModal(false);
      } else if (response.status === 201) {
        handleSuccessClick();
        stateChanger(true);
      } else {
        setErrorMessage('Something went wrong with your request, please try again.');
        errorChanger(true);
        showRegisterModal(false);
      }
    });
  };

  const FormSuccess = () => {
    return (
      <div className="success-view">
        <img alt="Success" src={successCheck} className="success-check" />
        <div className="success-text">Thank you for registering.</div>
      </div>
    );
  };

  return (
    <div>
      {!isSuccess && (
        <div className="register-form-wrapper font-loader">
          <form onSubmit={handleSubmit} className="form-layout">
            <BoostrapForm.Control
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              maxLength={100}
              type="text"
              required
              placeholder="First Name*"
              className="modal-input"
            />
            <BoostrapForm.Control
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              maxLength={100}
              type="text"
              required
              placeholder="Last Name*"
              className="modal-input"
            />
            {!emailValid && (
              <p style={{ color: 'red', margin: '-5px', fontSize: '12px' }}>
                enter a valid email address
              </p>
            )}
            <BoostrapForm.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => emailIsValid()}
              type="email"
              required
              placeholder="Email*"
              className="modal-input"
            />
            <BoostrapForm.Control
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              maxLength={15}
              type="text"
              required
              placeholder="Mobile Phone*"
              className="modal-input"
            />
            <div
              style={{
                fontStyle: 'italic',
                fontSize: '10px',
                marginRight: '149px',
                marginTop: '-14px'
              }}
            >
              *for notificiations only
            </div>
            <BoostrapForm.Select
              value={state}
              onChange={handleStateChange}
              required
              id="state"
              name="state"
              className="modal-input"
            >
              <option value="">State*</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="MP">Northern Marianas Islands</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="VI">Virgin Islands</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </BoostrapForm.Select>
            <BoostrapForm.Control
              value={npi}
              onChange={(e) => setNpi(e.target.value)}
              type="text"
              maxLength={10}
              required
              placeholder="NPI Number*"
              className="modal-input"
            />
            <button id="Register_2" type="submit" className="button-background">
              <GradientButton title="REGISTER" />
            </button>
          </form>
        </div>
      )}
      {isSuccess && <FormSuccess />}
    </div>
  );
}

export default Form;
